* {
  margin: 0;
  box-sizing: none;
}

.App {
  text-align: center;
  background-color: #1b1c2d;
  margin: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.closeBtn:hover {
  background:  #a5a5a5;
  
}

.itemsBtn:hover {
  background: #5b67a8;
}

.addButton {
  background: "lightYellow";
  margin: .4rem;
  border-radius: 3px;
  border: 1px solid #383838;
  padding-top: 5px;
  padding-bottom: 5px;
}

.addButton:hover {
  background-color: yellow;
}

.productCard {
  border: 2px solid black;
}

span {
  display: block;
}

.cart {
  color: white;
  text-align: left;
  padding-left: 1.5rem;
}
